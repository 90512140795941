import { Alert, Box, Button, Card, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import OptionChain from "./OptionChain";

export const OptionChainTab = () => {
  const [stockSymbol, setStockSymbol] = useState<string>("");
  const inputRef = useRef<any>(null);

  return (
    <>
      <Box>
        <Grid container>
          <Grid container paddingY={2} alignItems="center">
            <Grid item marginRight={2}>
              <InputLabel>Stock Symbol:</InputLabel>
            </Grid>
            <Grid item marginRight={2}>
              <TextField
                size="small"
                inputRef={inputRef}
                inputProps={{
                  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      setStockSymbol(inputRef.current?.value?.trim()?.toUpperCase());
                    }
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setStockSymbol(inputRef.current?.value?.trim() || "")}
              >
                Get Option Chain
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Box component="div">
            {stockSymbol === "" && (
              <Alert severity="info" sx={{ marginY: 1 }}>
                Please input correct stock symbol and press 'Enter' or click 'Get Option Chain' button
              </Alert>
            )}
            {stockSymbol !== "" && (
              <OptionChain stockSymbol={stockSymbol} key={stockSymbol}/>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};
