import { CircularProgress, Grid } from "@mui/material";
import { useAppDispatch, useAuth, useFactsetStreaming } from "features/store";
import {
  Strategy,
  StrategyGroup,
  useListStrategiesQuery,
  useListStrategyGroupsQuery,
} from "features/trading/accounts";
import { StrategyGroupTable } from "./StrategyGroupTable";
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { FDS_ActionTypes, FDS_SubscriptionTypes } from "constants/index";
import WebSocketWrapper from "ws/WebSocketWrapper";
import { addSubscription, cacheMessage, removeSubscription } from "features/factset-streaming/factsetStreamingSlice";
import { getDaysToExDividend, getStrategyOptionQty, parseOptionSymbol } from "helpers";

export const StrategyGroupsList = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();
  const { data: groupsData, isLoading: isGroupsLoading } = useListStrategyGroupsQuery(
    {},
    { skip: !isAuthenticated },
  );

  const { data: stratsData, isLoading: isStratsLoading, isFetching: isStratsFetching } = useListStrategiesQuery(
    {
      filters: {
        page_size: 100,
        detailed: true,
        exclude_non_group: true,
      },
    },
    {
      skip: !isAuthenticated,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, ...rest }) => ({
        data: {
          ...data,
          results: data?.results.map((strategy: Strategy) => {
            return {
              ...strategy,
              optionSymbolParams:
                strategy.current_call
                ? parseOptionSymbol(strategy.current_call__symbol!)
                : null,
            }
          }) || [],
        },
        ...rest,
      }),
    },
  );

  const {
    subscriptions,
    dataCache: {
      [FDS_SubscriptionTypes.HOMEPAGE_STOCK]: FDS_stockData,
      [FDS_SubscriptionTypes.HOMEPAGE_OPTION]: FDS_optionData,
    }
  } = useFactsetStreaming();

  const strategyGroups = useMemo(() => {
    if (isGroupsLoading || !groupsData) {
      return [];
    }

    return groupsData.results;
  }, [isGroupsLoading, groupsData]);

  const rowsByGroup = useMemo(() => {
    if (isStratsLoading || !stratsData) {
      return [];
    }

    const { results: strategies } = stratsData;

    const mappedStrats = strategies
      .map((strat: Strategy) => {
        const costBasis = parseFloat(
          strat.current_call_price
          || strat.cost_basis
          || "0"
        );

        const aum = strat.total_stocks_qty && strat.orion_stock_last_price
          ? strat.total_stocks_qty * parseFloat(strat.orion_stock_last_price)
          : 0;

        const fdsStock: any = FDS_stockData[strat.name];
        const stockLastPrice = fdsStock?.last_price && fdsStock.last_price !== "N/A" ? fdsStock.last_price : "N/A";
        const fdsOption: any = strat.current_call ? FDS_optionData[strat.current_call__symbol!] : null;
        const optionLastPrice = fdsOption?.last_price && fdsOption.last_price !== "N/A" ? fdsOption.last_price : "N/A";
        const optionDelta = fdsOption?.delta && fdsOption.delta !== "N/A" ? fdsOption.delta : "N/A";
        const gainNlossPerContract = optionLastPrice !== "N/A" ? costBasis - parseFloat(optionLastPrice) : "N/A";
        const totalGL = gainNlossPerContract !== "N/A"
          ? gainNlossPerContract * 100 * Math.abs(getStrategyOptionQty(strat))
          : "N/A";

        const exDate = strat.ex_date_next || strat.ex_date_last;
        const divAmt = strat.div_amt_next || strat.div_amt_last;
        const daysToExDividend = getDaysToExDividend(strat);

        return {
          ...strat,
          last_price: stockLastPrice !== "N/A" ? parseFloat(stockLastPrice).toFixed(2) : stockLastPrice,
          option_price: optionLastPrice !== "N/A" ? parseFloat(optionLastPrice).toFixed(2) : optionLastPrice,
          cost_basis: costBasis.toFixed(2),
          gain_n_loss_per_contract: gainNlossPerContract !== "N/A" ? gainNlossPerContract.toFixed(2) : gainNlossPerContract,
          totalGL,
          delta_mid: optionDelta,
          position_delta: optionDelta !== "N/A" ? parseFloat(optionDelta) * parseFloat(strat.hedge_ratio) : "N/A",
          dividend_ex_date: exDate,
          dividend_amt: divAmt,
          days_to_ex_dividend: daysToExDividend,
          aum,
        };
      });

    const grouped = mappedStrats.reduce((acc: any, cur: any) => {
      const group = cur.strategy_group;
      if (group in acc) {
        acc[group].push(cur);
      } else {
        acc[group] = [cur];
      }

      return acc;
    }, {});

    for (const key of Object.keys(grouped)) {
      grouped[key] = grouped[key]?.sort((a: Strategy, b: Strategy) => {
          const isOpenCallA = Number(!a.current_call);
          const isOpenCallB = Number(!b.current_call);
          const expirationDateA = isOpenCallA ? '1970-00-00' : a.current_call__symbol?.slice(6, 12);
          const expirationDateB = isOpenCallB ? '1970-00-00' : b.current_call__symbol?.slice(6, 12);
          const currentCallDiff = isOpenCallA - isOpenCallB;
          const expirationDateDiffAsc = dayjs(expirationDateA, "YYMMDD").diff(
            dayjs(expirationDateB, "YYMMDD"),
          );
          const aumDiffDesc = b.aum! - a!.aum!;
          return currentCallDiff || expirationDateDiffAsc || aumDiffDesc;
        });
    }

    return grouped;
  }, [
    isStratsLoading,
    stratsData,
    FDS_stockData,
    FDS_optionData,
  ]);

  const stockSubKey = useMemo(() => {
    if (!stratsData || stratsData.results.length === 0) {
      return null;
    }

    const stockSymbols = stratsData.results.map((v => v.name + "-USA"));

    if (stockSymbols.length === 0) {
      return null;
    }

    return FDS_SubscriptionTypes.HOMEPAGE_STOCK + ":" + stockSymbols.join(",");
  }, [stratsData]);

  const optionSubKey = useMemo(() => {
    if (!stratsData) {
      return null;
    }

    const optionSymbols = stratsData.results
      .filter(v => v.current_call)
      .map((v => v.current_call__symbol + "-USA"))
      .map((v) => v?.replace(/ +/g, "#"));

    if (optionSymbols.length === 0) {
      return null;
    }

    return FDS_SubscriptionTypes.HOMEPAGE_OPTION + ":" + optionSymbols.join(",");
  }, [stratsData]);

  useEffect(() => {
    if (!stockSubKey || !optionSubKey) {
      return;
    }

    const ws = new WebSocketWrapper();

    if (!(stockSubKey in subscriptions || optionSubKey in subscriptions)) {
      ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

      ws.on("open", (ev: any) => {
        if (stockSubKey && !(stockSubKey in subscriptions)) {
          const [_, symbols] = stockSubKey.split(":");
          dispatch(addSubscription(stockSubKey));
          ws.send(JSON.stringify({
            action: FDS_ActionTypes.SUBSCRIBE,
            type: FDS_SubscriptionTypes.HOMEPAGE_STOCK,
            symbol: symbols,
          }));
        }

        if (optionSubKey && !(optionSubKey in subscriptions)) {
          const [_, symbols] = optionSubKey.split(":");
          dispatch(addSubscription(optionSubKey));
          ws.send(JSON.stringify({
            action: FDS_ActionTypes.SUBSCRIBE,
            type: FDS_SubscriptionTypes.HOMEPAGE_OPTION,
            symbol: symbols,
          }));
        }
      });

      ws.on("message", (ev: any) => {
        if (ev.data !== "ok") {
          dispatch(cacheMessage(JSON.parse(ev.data)));
        }
      });

      ws.on("close", (ev: any) => {
        if (stockSubKey) {
          dispatch(removeSubscription(stockSubKey));
        }
        if (optionSubKey) {
          dispatch(removeSubscription(optionSubKey));
        }
      });
    }

    return () => {
      if (stockSubKey) {
        const [_, symbols] = stockSubKey.split(":");
        dispatch(removeSubscription(stockSubKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.UNSUBSCRIBE,
          type: FDS_SubscriptionTypes.HOMEPAGE_STOCK,
          symbol: symbols,
        }));
      }

      if (optionSubKey) {
        const [_, symbols] = optionSubKey.split(":");
        dispatch(removeSubscription(optionSubKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.UNSUBSCRIBE,
          type: FDS_SubscriptionTypes.HOMEPAGE_OPTION,
          symbol: symbols,
        }));
      }
      ws.disconect();
    };
  }, [stockSubKey, optionSubKey]);

  if (isGroupsLoading || isStratsLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      {strategyGroups &&
        strategyGroups.map((group: StrategyGroup) => {
          return (
            <Grid item xs={12}>
              <StrategyGroupTable
                group={group}
                isLoading={isGroupsLoading || isStratsLoading}
                isFetching={isStratsFetching}
                rows={rowsByGroup[group.id] || []}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};
