import { Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import { useAppDispatch, useTrading } from "features/store";
import { useListStrategiesQuery } from "features/trading/accounts";
import { setCurrentStrategy } from "features/trading/tradingSlice";
import { getLastStrategyId, getStrategyPickerHighlightColor } from "helpers";
import { useEffect, useState } from "react";

export const StrategyPicker = () => {
  const { currentStrategyGroupId, currentStrategy } = useTrading();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useListStrategiesQuery(
    {
      pagination: { page: 0, pageSize: 100 },
      filters: { strategy_group: currentStrategyGroupId, include_last_call_close: true },
    },
    { skip: !currentStrategyGroupId },
  );

  useEffect(() => {
    const lastStrategyId = currentStrategy?.id || getLastStrategyId();
    if (
      data &&
      data.results.length > 0
    ) {
      dispatch(
        setCurrentStrategy(
          data.results.find((strategy) => strategy.id === lastStrategyId) || data.results[0]
        )
      );
    }
  }, [data, currentStrategy, dispatch, setCurrentStrategy]);

  return data &&
    !isLoading &&
    data.results.some((strategy) => strategy.id === currentStrategy?.id) ? (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Select
        labelId="account-label"
        label="Account"
        size="small"
        value={currentStrategy ? currentStrategy.id : ""}
        sx={{ width: 250, borderRadius: 5 }}
        onChange={(event) => {
          const strategy = data.results.find(strategy => strategy.id === event.target.value);
          dispatch(setCurrentStrategy(strategy));
        }}
      >
        {data.results.map((strategy) => (
          <MenuItem key={strategy.id} value={strategy.id} sx={{ background: getStrategyPickerHighlightColor(strategy) }}>
            {strategy.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  ) : (
    <CircularProgress />
  );
};
