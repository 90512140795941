import {
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DEFAULT_LIMIT_PRICE, OrderType } from "constants/index";
import { useAppDispatch, useTrading } from "features/store";
import { setLimitPrice, setOrderType } from "features/trading/tradingSlice";
import { useEffect } from "react";

export const OrderTypeSetup = () => {
  const dispatch = useAppDispatch();
  const { orderType, limitPrice, currentStrategy } = useTrading();

  const handleOrderTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setOrderType(event.target.value));
  };

  const handleLimitPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setLimitPrice(parseFloat(Number(event.target.value).toFixed(2))));
  };

  useEffect(() => {
    if (orderType === OrderType.MARKET) {
      dispatch(setLimitPrice(DEFAULT_LIMIT_PRICE));
    }
  }, [orderType, dispatch, setLimitPrice, DEFAULT_LIMIT_PRICE]);

  useEffect(() => {
    if (orderType === OrderType.LIMIT) {
      dispatch(setLimitPrice(DEFAULT_LIMIT_PRICE));
    }
  }, [dispatch, setLimitPrice, DEFAULT_LIMIT_PRICE, currentStrategy]);

  return (
    <Grid container paddingY={1} alignItems="center">
      <Grid item xs={2}>
        <InputLabel>Order Type:</InputLabel>
      </Grid>
      <Grid item xs={10}>
        <RadioGroup row value={orderType} onChange={handleOrderTypeChange}>
          <FormControlLabel
            value={OrderType.MARKET}
            control={<Radio />}
            label="Market"
          />
          <FormControlLabel
            value={OrderType.LIMIT}
            control={<Radio />}
            label="Limit"
          />
        </RadioGroup>
      </Grid>
      {orderType === OrderType.LIMIT && (
        <>
          <Grid item xs={2}>
            <InputLabel>Limit Price:</InputLabel>
          </Grid>
          <Grid item xs={10}>
            <TextField
              size="small"
              value={limitPrice}
              type="number"
              onChange={handleLimitPriceChange}
              sx={{ width: "30ch" }}
              inputProps={{
                min: 0.00,
                step: 0.01,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
