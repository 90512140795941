import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OptionChain from "../OptionChain";
import { useTrading } from "features/store";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const OptionChainModal = ({ isOpened, onClose }: Props) => {
  const { currentStrategy } = useTrading();

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box component="div" sx={{ width: "1200px" }}>
          <Card>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  Option Chain
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <OptionChain stockSymbol={currentStrategy?.name} onClose={onClose} inModal />
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
