import { StrategyGroupPicker } from "components/holdings/StrategyGroupPicker";
import Layout from "../components/Layout";
import { Alert, Box, Card, IconButton, Tooltip } from "@mui/material";
import { StrategyPicker } from "components/holdings/StrategyPicker";
import StrategyPositionsList from "components/holdings/StrategyPositionsList";
import SettlementDatePicker from "components/holdings/SettlementDatePicker";
import { StrategyTradeSetup } from "components/holdings/StrategyTradeSetup";
import { useCallback } from "react";
import SyncEnabledIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { useAppDispatch, useTrading } from "features/store";
import { setPositionsPollingInterval } from "features/trading/tradingSlice";
import { DEFAULT_POLLING_INTERVAL, StrategyGroupEnum } from "constants/index";
import { NotIncludedAccounts } from "components/holdings/NotIncludedAccounts";
import { FillWithAccountsButton } from "components/holdings/FillWithAccountsButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function HomePage() {
  const dispatch = useAppDispatch();
  const { positionsPollingInterval } = useTrading();
  const togglePolling = useCallback(() => {
    dispatch(setPositionsPollingInterval(
      positionsPollingInterval === DEFAULT_POLLING_INTERVAL ? 0 : DEFAULT_POLLING_INTERVAL
    ));
  }, [dispatch, positionsPollingInterval, setPositionsPollingInterval]);
  const { currentStrategy } = useTrading();

  return (
    <Layout title="Strategies" content="Strategies">
      <Card>
        <Box display={"flex"} flexDirection={"row"} alignContent={"center"}>
          <StrategyGroupPicker />
          <StrategyPicker />
          {currentStrategy &&
            currentStrategy?.strategy_group != StrategyGroupEnum.STANDARD && (
              <FillWithAccountsButton />
            )}
          <Box flexGrow={1} />
          <Tooltip title={`${positionsPollingInterval === DEFAULT_POLLING_INTERVAL ? "Disable": "Enable"} auto refresh`}>
            <IconButton onClick={togglePolling} sx={{ marginX: 1 }}>
              {positionsPollingInterval ? (<SyncEnabledIcon />) : (<SyncDisabledIcon />)}
            </IconButton>
          </Tooltip>
          <SettlementDatePicker />
        </Box>
        <StrategyTradeSetup />
        {currentStrategy && currentStrategy.last_call_close && (
          <Alert
            severity="warning"
            sx={{ margin: 2 }}
            >
              You've already traded that call (last time that was closed on {dayjs.utc(currentStrategy.last_call_close).tz(dayjs.tz.guess()).format("MM/DD/YYYY hh:mm:ss A")})
          </Alert>
        )}
        <StrategyPositionsList />
        <NotIncludedAccounts />
      </Card>
    </Layout>
  );
}
