import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useNewCallMutation } from "features/trading/accounts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import { formatFactsetNumberValue, mapOptionName } from "helpers";
import { useAppDispatch, useFactsetStreaming, useTrading } from "features/store";
import { setCurrentStrategy } from "features/trading/tradingSlice";
import { addSubscription, cacheMessage, removeSubscription } from "features/factset-streaming/factsetStreamingSlice";
import WebSocketWrapper from "ws/WebSocketWrapper";
import { FDS_ActionTypes, FDS_SubscriptionTypes } from "constants/index";

interface Props {
  expirationDate: string;
  options: any[];
  selectedOption?: any;
  setSelectedOption: Function;
  closeModal?: Function;
  onOpen: Function;
  onClose: Function;
  opened: boolean;
  inModal?: boolean;
}

const ColsDefinition = [
  {
    header: "Symbol",
    key: "key",
  },
  {
    header: "Last Price",
    key: "last_price",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Ask",
    key: "ask",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Bid",
    key: "ask",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Strike Price",
    key: "strike_price",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Impl Vol.",
    key: "impl_vol",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Theta",
    key: "theta",
    formatter: formatFactsetNumberValue,
  },
  {
    header: "Delta",
    key: "delta",
    formatter: formatFactsetNumberValue,
  },
];

export const OptionGroup = ({
  expirationDate,
  options,
  selectedOption,
  setSelectedOption,
  closeModal,
  opened,
  onOpen,
  onClose,
  inModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const { currentStrategy } = useTrading();
  const {
    subscriptions,
    dataCache: {
      [FDS_SubscriptionTypes.OPTION_CONTRACT]: optionContracts,
      [FDS_SubscriptionTypes.STOCK_PRICE]: stockPrices,
    }
  } = useFactsetStreaming();

  const [popoverAnchor, setPopoverAnchor] = useState();
  const mountPopover =
    options.findIndex(
      (opt: any) => opt.key === selectedOption?.key,
    ) !== -1;
  const [newCall, newCallRes] = useNewCallMutation();

  useEffect(() => {
    const { data, isSuccess } = newCallRes;
    if (isSuccess) {
      dispatch(setCurrentStrategy(data));
    }
  }, [newCallRes, dispatch, setCurrentStrategy]);

  const onSelectConfirm = useCallback(() => {
    if (currentStrategy && selectedOption) {
      const asOfDate = dayjs().format("YYYY-MM-DD");
      const optionName = mapOptionName(
        currentStrategy?.name,
        dayjs(String(selectedOption.expiration_date)),
        selectedOption.strike_price,
      );
      newCall({
        id: currentStrategy.id,
        as_of_date: asOfDate,
        name: optionName,
        symbol: selectedOption.key,
      });
      setSelectedOption(undefined);
      setPopoverAnchor(undefined);
      if (closeModal) {
        closeModal();
      }
      onClose();
    }
  }, [
    currentStrategy,
    selectedOption,
    dayjs,
    mapOptionName,
    newCall,
    setSelectedOption,
    setPopoverAnchor,
    closeModal,
  ]);

  const onSelectCancel = () => {
    setSelectedOption(undefined);
    setPopoverAnchor(undefined);
  };

  const expirationDaysTo = Math.abs(dayjs().diff(dayjs(expirationDate, "YYYYMMDD"), "d"));

  const expDateHeader = `${dayjs(expirationDate).format("ddd, MMM DD YYYY")} (${expirationDaysTo} days remaning)`;

  const optionContractsKey = useMemo(() => {
    if (!opened) {
      return null;
    }

    if (options.length === 0) {
      return null;
    }

    const mappedSymbols = options
                            .map((v: any) => v.key + "-USA")
                            .map((v: any) => v?.replace(/ +/g, "#"))
                            .sort()
                            .join(",");

    return FDS_SubscriptionTypes.OPTION_CONTRACT + ":" + mappedSymbols;
  }, [opened, options]);

  useEffect(() => {
    if (!optionContractsKey) {
      return;
    }

    const ws = new WebSocketWrapper();
    if (!(optionContractsKey in subscriptions)) {
      ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

      ws.on("open", (ev: any) => {
        const [_, symbols] = optionContractsKey.split(":");
        dispatch(addSubscription(optionContractsKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.SUBSCRIBE,
          type: FDS_SubscriptionTypes.OPTION_CONTRACT,
          symbol: symbols,
        }));
      });

      ws.on("message", (ev: any) => {
        if (ev.data !== "ok") {
          dispatch(cacheMessage(JSON.parse(ev.data)));
        }
      });

      ws.on("close", (ev: any) => {
        if (optionContractsKey) {
          dispatch(removeSubscription(optionContractsKey));
        }
      });
    }

    return () => {
      if (ws.socket?.readyState === WebSocket.OPEN && optionContractsKey) {
        const [_, symbols] = optionContractsKey.split(":");
        dispatch(removeSubscription(optionContractsKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.UNSUBSCRIBE,
          type: FDS_SubscriptionTypes.OPTION_CONTRACT,
          symbol: symbols,
        }));
        ws.disconect();
      }
    }
  }, [optionContractsKey]);

  const stockLastPrice =  useMemo(() => {
    if (!currentStrategy) {
      return 0;
    }

    return parseFloat(stockPrices[currentStrategy.name]?.last_price || "0");
  }, [stockPrices, currentStrategy]);

  const premappedOptions = useMemo(() => {
    return options.map((opt) => {
      const inTheMoney = stockLastPrice > parseFloat(opt.strike_price || "0");
      return { ...opt, inTheMoney };
    });
  }, [options, stockLastPrice]);

  const rows = useMemo(() => {
    return premappedOptions.map((opt) => {
      const contractItem = optionContracts[opt.key];
      if (!contractItem) {
        return { ...opt };
      }
      return { ...opt, ...contractItem };
    });
  }, [premappedOptions, optionContracts]);

  return (
    <div>
      <Accordion expanded={opened}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            if (!opened) {
              onOpen();
            } else {
              onClose();
            }
          }}
        >
          {expDateHeader}
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                {ColsDefinition.map((colDef) => (
                  <TableCell align="center">{colDef.header}</TableCell>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((opt: any) => (
                  <TableRow
                    hover
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        opt.inTheMoney
                        ? "rgba(83,83,83,0.1)"
                        : "inherit",
                    }}
                    onClick={(e) => {
                      if (inModal) {
                        setSelectedOption(opt);
                        setPopoverAnchor(e?.currentTarget as any);
                      }
                    }}
                  >
                    {ColsDefinition.map((colDef) => (
                      <TableCell
                        align="center"
                        style={
                          colDef.key === "key" ? { whiteSpace: "pre" } : {}
                        }
                      >
                        {colDef.formatter ? colDef.formatter(opt[colDef.key] || "") : opt[colDef.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {mountPopover && (
        <Popover
          open={!!selectedOption}
          anchorEl={popoverAnchor}
          onClose={onSelectCancel}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box component="div" sx={{ padding: 1 }}>
            <Typography sx={{ padding: 1 }} textAlign="center">
              Confirm Option Choice
            </Typography>
            <Typography sx={{ paddingX: 1 }}>
              Symbol: <b>{selectedOption?.key}</b>
            </Typography>
            <Typography sx={{ paddingX: 1 }}>
              Expiration Date:{" "}
              <b>
                {dayjs(String(selectedOption?.expiration_date)).format("MM/DD/YYYY")} (
                {selectedOption?.expiration_days_to} days remaining)
              </b>
            </Typography>
            <Typography sx={{ paddingX: 1 }}>
              Strike Price: <b>{selectedOption?.strike_price}</b>
            </Typography>
            <Grid container justifyContent="space-evenly">
              <Grid item>
                <IconButton onClick={onSelectConfirm}>
                  <ConfirmIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Button onClick={onSelectCancel}>
                  <CancelIcon />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      )}
    </div>
  );
};
