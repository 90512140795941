import { LocalStorageKeys } from "constants/index";
import dayjs, { Dayjs } from "dayjs";
import {
  FactSetOption,
  Strategy,
  StrategyPosition,
} from "features/trading/accounts";
import { TradeMode } from "features/trading/tradingSlice";

export const getTradeModeLabel = (mode: TradeMode) => {
  switch (mode) {
    case TradeMode.CallSellShort:
      return "Sell Short";
    case TradeMode.CallBuyToCover:
      return "Buy to Cover";
    case TradeMode.CallRebalance:
      return "Rebalance";
    default:
      return "Unknown";
  }
};

export const asMillionsCurrency = (value: number) => {
  const millionsValue = value / 1_000_000;

  const currencyFormatted = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 3,
  }).format(millionsValue);

  return `${currencyFormatted}M`;
};

export const getOnOrder = (position: StrategyPosition) => {
  const { on_order_sell, on_order_buy } = position;
  const onOrderSell = -parseInt(on_order_sell || "0");
  const onOrderBuy = parseInt(on_order_buy || "0");
  return onOrderSell + onOrderBuy;
};

export const getExecutedOrder = (position: StrategyPosition) => {
  const { executed_order_sell, executed_order_buy } = position;
  const executedOrderSell = -parseInt(executed_order_sell || "0");
  const executedOrderBuy = parseInt(executed_order_buy || "0");
  return executedOrderSell + executedOrderBuy;
};

export const isCallExpired = (strategy: Strategy) => {
  const { current_call__name } = strategy;
  if (current_call__name === null) {
    return true;
  }
  const date = current_call__name.split(" ")[2];
  const daysDiff = dayjs(date).diff(dayjs(), "day");
  return daysDiff < 0;
};

export const mapOptionSymbol = (
  stock: string,
  expDate: Dayjs,
  strikePrice: number,
) => {
  const underlyingPart = stock.padEnd(6, " ");
  const expDatePart = expDate.format("YYMMDD");
  const strikePricePart = Number(strikePrice.toFixed(2))
    .toFixed(3)
    .replace(".", "")
    .padStart(8, "0");

  return underlyingPart + expDatePart + "C" + strikePricePart;
};

export const mapOptionName = (
  stock: string,
  expDate: Dayjs,
  strikePrice: string,
  optionType: string = "C",
) => {
  return `${optionType === "C" ? "Call": "Put"} ${stock} ${expDate.format("M-D-YY")} @ ${Number(strikePrice).toFixed(2).replace(".00", "")}`;
};

export const updateLastStrategyGroupId = (id?: number) => {
  if (id) {
    localStorage.setItem(LocalStorageKeys.LAST_STRATEGY_GROUP_ID, String(id));
  } else {
    localStorage.removeItem(LocalStorageKeys.LAST_STRATEGY_GROUP_ID);
  }
};

export const updateLastStrategy = (strategy: Strategy) => {
  if (strategy) {
    localStorage.setItem(
      LocalStorageKeys.LAST_STRATEGY_ID,
      String(strategy.id),
    );
    localStorage.setItem(
      LocalStorageKeys.LAST_STRATEGY_GROUP_ID,
      String(strategy.strategy_group),
    );
  } else {
    localStorage.removeItem(LocalStorageKeys.LAST_STRATEGY_ID);
    localStorage.removeItem(LocalStorageKeys.LAST_STRATEGY_GROUP_ID);
  }
};

export const getLastStrategyId = () =>
  Number(localStorage.getItem(LocalStorageKeys.LAST_STRATEGY_ID) || "0");
export const getLastStrategyGroupId = () =>
  Number(localStorage.getItem(LocalStorageKeys.LAST_STRATEGY_GROUP_ID) || "0");

export const adjustFactsetOptionSymbol = (opt: FactSetOption) => {
  const { REQ_SYM, ...rest } = opt;
  const reqSym = REQ_SYM.replace("-USA", "");
  const symbolParts = reqSym.split("#");
  return { ...rest, REQ_SYM: symbolParts[0].padEnd(6, " ") + symbolParts[1] };
};

export const generateStrikeIntervals = (maxValue: number, step: number = 5) => {
  const intervals = [];

  for (let i = step; i <= maxValue; i += step) {
    intervals.push(i);
  }

  return intervals;
};

export const getStrategyPickerHighlightColor = (strategy: Strategy) => {
  const { current_call__symbol: symbol } = strategy;
  if (symbol === null) {
    return "rgba(0,255,0,0.3)";
  }

  const expDate = dayjs(symbol.slice(6,12), "YYMMDD");
  const daysDiff = dayjs(expDate).diff(dayjs(), "day");
  if (daysDiff === 0) {
    return "rgba(255,0,0,0.3)";
  } else if (daysDiff < 7) {
    return "rgba(255,255,0,0.3)";
  }

  return "#fff";
};

export const getPositionHighlightColor = (pos: StrategyPosition) => {
  const { overriden_option_qty } = pos;
  if (overriden_option_qty !== null) {
    return "rgba(255,255,0,0.8)";
  }

  return "inherit";
};

export const getOptionQty = (pos: StrategyPosition) => {
  const { overriden_option_qty, option_qty } = pos;
  if (overriden_option_qty !== null || option_qty !== null) {
    return parseInt(overriden_option_qty || option_qty);
  }

  return null;
};

export const formatFactsetNumberValue = (value: string) => {
  if (!value || value.length === 0 || value === "N/A") {
    return "N/A";
  }

  const numValue = parseFloat(value);
  return numValue.toFixed(2);
}

export interface OptionSymbolParams {
  stockSymbol?: string;
  expirationDate?: dayjs.Dayjs;
  type?: string;
  strikePrice?: number;
  isCorrect: boolean;
}

export const parseOptionSymbol = (symbol: string): OptionSymbolParams => {
  const symbolParams: OptionSymbolParams = { isCorrect: true };
  if (symbol.length !== 21) {
    symbolParams.isCorrect = false;
    return symbolParams;
  }

  symbolParams.stockSymbol = symbol.slice(0, 6).trim();
  symbolParams.expirationDate = dayjs(symbol.slice(6, 12), "YYMMDD");
  symbolParams.type = symbol.slice(12, 13);
  symbolParams.strikePrice = parseInt(symbol.slice(13)) / 1_000;

  return symbolParams;
}

export const mapOptionNameFromParams = (params: OptionSymbolParams) => {
  if (params.isCorrect) {
    const { stockSymbol, expirationDate, strikePrice, type } = params;
    if (stockSymbol && expirationDate && strikePrice && type) {
      return mapOptionName(stockSymbol, expirationDate, String(strikePrice), type);
    }

    return "Missing option symbols params";
  }

  return "Incorrect option symbol";
};

export const getStrategyOptionQty = (strategy: Strategy) => {
  const optionQty = parseInt(strategy.option_qty || "0");
  const executedSellQty = parseInt(strategy.executed_sell_qty || "0");
  const executedBuyQty = parseInt(strategy.executed_buy_qty || "0");
  return optionQty + executedSellQty + executedBuyQty;
};

export const getDaysToExDividend = (strategy: Strategy) => {
  const exDate = strategy.ex_date_next || strategy.ex_date_last;
  if (!exDate) {
    return null;
  }

  const today = dayjs().startOf('day');
  const exDateDayjs = dayjs(exDate);

  const daysToExDividend = exDateDayjs.diff(today, 'days');
  return daysToExDividend;
};
