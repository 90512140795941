import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import {
  Logout,
  Person,
  SettingsInputComposite,
  StackedLineChart,
  Percent,
  AccountBalance,
  Inventory,
  Assignment,
  DoDisturb,
} from "@mui/icons-material";
import { LOGO_URL, LOGO_URL_SMALL } from "config";

import { useAppDispatch, useAuth, useLayout } from "features/store";

import { useLogoutMutation } from "features/auth/auth";
import { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { toggleDrawer } from "features/layout/layoutSlice";
import ViewListIcon from "@mui/icons-material/ViewList";

const SideMenu = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const { drawerOpen, drawerWidth } = useLayout();
  const dispatch = useAppDispatch();

  const [logout] = useLogoutMutation();

  const onLogout = () => {
    logout();
  };

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer());
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  const menu = [
    { icon: <HomeIcon />, text: "Home", link: "/" },
    { icon: <Inventory />, text: "Strategies", link: "/stock" },
    { icon: <Assignment />, text: "Orders", link: "/orders" },
    { icon: <Person />, text: "Clients", link: "/clients" },
    { icon: <AccountBalance />, text: "Accounts", link: "/accounts" },
    {
      icon: <SettingsInputComposite />,
      text: "Composite",
      link: "/composite",
    },
    { icon: <Percent />, text: "Div Optic", link: "/composite" },
    { icon: <StackedLineChart />, text: "Growth Optic", link: "/composite" },
    { icon: <DoDisturb />, text: "Non Optic", link: "/non_optic" },
    { icon: <ViewListIcon />, text: "Option Chain", link: "/option_chain" },
  ];

  const drawer = (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100vh"}
      overflow={"hidden"}
    >
      <Box height={65} justifyContent={"center"} display={"flex"}>
        <IconButton onClick={handleDrawerToggle}>
          <img
            width={drawerOpen ? 150 : 50}
            src={drawerOpen ? LOGO_URL : LOGO_URL_SMALL}
          />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menu.map(({ icon, text, link }, index) => (
          <ListItem key={text} disablePadding>
            <Link to={link}>
              <ListItemButton>
                <ListItemIcon> {icon}</ListItemIcon>
                <ListItemText primary={text} hidden={!drawerOpen} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Box flexGrow={1} />
      <Divider />
      <List>
        <ListItem key="logout" disablePadding>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={"Logout"} hidden={!drawerOpen} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideMenu;
