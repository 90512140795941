import { Card, Grid } from "@mui/material";
import { OptionChainTab } from "components/holdings/OptionChainTab";
import Layout from "components/Layout";

const OptionChainPage = () => {
  return (
    <Layout title="Option Chain">
      <Card>
        <Grid container>
          <Grid item xs={12} padding={2}>
            <OptionChainTab />
          </Grid>
        </Grid>
      </Card>
    </Layout>
  );
};

export default OptionChainPage;
